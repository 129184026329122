import React from 'react'
// import { HiArrowNarrowRight } from 'react-icons/hi'
import Profile from '../assets/profile.jpeg'
import { Link } from 'react-scroll'

const Home = () => {
  const profile = <Link to='work'>View Work</Link>
  return (
    <div id='home' className='w-full h-screen bg-[#0a192f]'>
      <div className='invisible sm:visible absolute top-20 right-20 w-20 hover:scale-110 duration-500'>
        <img
          src={Profile}
          alt='Joseph Ani'
          className='shadow rounded-full max-w-full h-auto align-middle border-none'
        />
      </div>

      <div className='max-w-[1000px] mx-auto px-8 flex flex-col justify-center h-full'>
        <p className='text-[rgb(249,115,115)]'>Hi, my name is</p>
        <h1 className='text-4xl sm:text-7xl font-bold text-[#ccd6f6]'>
          Joseph Ani
        </h1>
        <h2 className='text-4xl sm:text-7xl font-bold text-[#8892b0]'>
        Data-Driven Software Engineer
        </h2>
        <p className='indent-8 text-[#8892b0] py-4 max-w-[700px] first-letter:text-white'>
        Experienced software engineer with a passion for integrating machine learning and AI into data-driven solutions. Proficient in a range of programming languages and frameworks, with a particular talent for adapting AI technologies to enhance application performance and user engagement. Demonstrated ability in managing large datasets and deploying TensorFlow models. Looking to leverage my software development background and interest in AI/ML to drive innovation in engineering projects.
        </p>
        <div>
          <button className={'text-white group border-2 px-6 py-3 my-2 flex items-center hover:bg-[rgb(249,115,115)] hover:border-[rgb(249,115,115)]'}>
            {profile}
            {/* <span className={'group-hover:rotate-90 duration-300'}>
              <HiArrowNarrowRight className='ml-3 ' />
            </span> */}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Home
