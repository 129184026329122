import React from 'react'

const About = () => {
  return (
    <div id='about' className='w-full h-screen bg-[#0a192f] text-gray-300'>
      <div className='flex flex-col justify-center items-center w-full h-full'>
        <div className='max-w-[1000px] w-full grid grid-cols-2 gap-8'>
          <div className='sm:text-right pb-8 pl-4'>
            <p className='text-4xl font-bold inline border-b-4 border-[rgb(249,115,115)]'>
              About
            </p>
          </div>
          <div></div>
        </div>
        <div className='max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4'>
          <div className='sm:text-right text-4xl font-bold'>
            <p>Hi, nice to meet you. Please take a look around.</p>
          </div>
          <div>
            <p class="first-line:uppercase first-line:tracking-widest first-letter:text-7xl first-letter:font-bold first-letter:text-white first-letter:mr-3 first-letter:float-left">
            I understand that your vision is important to you. You have taken the time to create it, and you don't want it to be altered or compromised. That's why I treat every project with the care and attention it deserves.
            I bring years of experience in software development, human centered design, data science and visualization to every project. I'll help you make sense of your project and discover insights that will help you make better business decisions.
            I am always pushing the boundaries of what is possible. I am constantly working on new and innovative ways to solve problems.
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About
