import React from 'react';
// import { Link } from 'react-scroll'
import { FaLinkedin, FaGithub,FaXTwitter,  FaMedium } from 'react-icons/fa6';


const Footer = () => {
    const year = new Date().getFullYear()
    return <div>
        <section id="footer" className='bg-[#f97173] md:px-28'>
            <div className='container flex flex-col items-center justify-between px-6 py-24 mx-auto space-y-12 md:py-12 md:flex-row md:space-y-0'>
                <h2 className='md:text-4xl font-bold text-2xl text-blue '>Need to reach me</h2>
                <div className='flex md:justify-start pt-6'>
                    <li className='rounded-full  h-[30px] flex justify-between items-center'>
            <a
              className='w-15 text-gray-300'
              href='https://www.linkedin.com/in/josephani'
            >
               <FaLinkedin size={50} />
            </a>
          </li>
          <li className='rounded-full w-[100px] h-[30px] flex justify-between items-center '>
            <a
              className='w-15 text-gray-300'
              href='https://github.com/JosephAni/'
            >
              <FaGithub size={50} />
            </a>
          </li>
          <li className='rounded-full w-[100px] h-[30px] flex justify-between items-center'>
            <a
              className='w-15 text-gray-300'
              href='https://twitter.com/joeoani'
            >
             <FaXTwitter size={50} />
            </a>
          </li>
          <li className='rounded-full w-[100px] h-[30px] flex justify-between items-center'>
            <a
              className='w-15 text-gray-300'
              href='https://medium.com/@joeoani'
            >
             <FaMedium size={50} />
            </a>
          </li>
                </div>
            </div>
        </section>
        {/* background */}
       <div className='bg-gray-900 md:px-28'>
            {/* container */}
        <div className='container flex flex-col-reverse justify-between px-6 py-10 mx-auto space-y-8 md:flex-row md:space-y-0'>
            <div className='flex flex-col-reverse items-center justify-between space-x-12 md:flex-col md:space-y-0 md:items-start'>
                <div className='mx-auto my-6 text-center text-white md:hidden'>
                Copyright &copy; {year} Joseph Ani
                </div>
            </div>
                {/* two */}
            </div>
                {/* <div className='grid justify-items-center space-y-3 text-white'>
                <Link to="/about" className='nav-links'>About</Link>
                <Link to="/contact" className='nav-links'>Contact</Link>
                <Link to="/home" className='nav-links'>Home</Link>
                <Link to="/work" className='nav-links'>Work</Link>
                <Link to="/skills" className='nav-links'>Skills</Link>
                
            </div> */}
            <div className='hidden text-white md:block'>
                Copyright &copy; {year} Joseph Ani
            </div>
        </div>
    </div>;
}
 
export default Footer;