import React, { useState } from 'react';

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Form is valid, submit the form
      e.target.submit();
    } else {
      // Form is not valid, display an error message or take appropriate action
      console.log('Please fill in all the required fields');
    }
  };

  const validateForm = () => {
    if (name.trim() === '' || email.trim() === '' || message.trim() === '') {
      return false;
    }

    return true;
  };

  return (
    <div
      id='contact'
      className='w-full h-screen bg-[#0a192f] flex justify-center items-center p-4'
    >
      <form
        method='POST'
        action='https://getform.io/f/5e83be30-22a7-4558-b381-7edcfaf89921'
        className='flex flex-col max-w-[600px] w-full'
        onSubmit={handleSubmit}
      >
        <div className='pb-8'>
          <p className='text-4xl font-bold inline border-b-4 border-pink-600 text-gray-300'>
            Contact
          </p>
          <p className='text-gray-300 py-4'>Submit the form below</p>
        </div>
        <input
          className='bg-[#ccd6f6] p-2'
          type='text'
          placeholder='Name'
          name='name'
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          className='my-4 p-2 bg-[#ccd6f6]'
          type='email'
          placeholder='Email'
          name='email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <textarea
          className='bg-[#ccd6f6] p-2'
          name='message'
          rows='10'
          placeholder='Message'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
        ></textarea>
        <button className='text-white border-2 hover:bg-[rgb(249,115,115)] hover:border-[rgb(249,115,115)] px-4 py-3 my-8 mx-auto flex items-center'>
          Let's Chat
        </button>
      </form>
    </div>
  );
};

export default Contact;


