import React from 'react'
import sql from '../assets/sql.jpeg'
import tensorflow from '../assets/tensorflow.jpeg'
import reactImg from '../assets/react.png'
import node from '../assets/node.png'
import next from '../assets/next.jpeg' // fixed file extension
import vertex from '../assets/vertex.jpeg'
import tailwind from '../assets/tailwind.png'
import python from '../assets/python.jpeg'

const Skills = () => {
  return (
    <div className='w-full h-screen bg-[#0a192f] text-gray-300'>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div>
          <p className='text-4xl font-bold inline border-b-4 border-[rgb(249,115,115)]'>
            Skills
          </p>
          <p className='py-4'>These are the technologies I've worked with</p>   
        </div>
        <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
          <div className='shadow-md shadow-[#040c16]'>
            <img className='w-full mx-auto' src={tensorflow} alt='tensorflow icon'/>
            {/* <p className='my-4'>TENSORFLOW</p> */}
          </div>
          <div className='shadow-md shadow-[#040c16]'>
            <img className='object-fit h-40 w-86' src={reactImg} alt='react icon' />
            {/* <p className='my-4'>REACT</p> */}
          </div>
          <div className='shadow-md shadow-[#040c16]'>
            <img className='object-fit h-40 w-86' src={node} alt='node js icon' />
            {/* <p className='my-4'>Node js</p> */}
          </div>
          <div className='shadow-md shadow-[#040c16]'>
            <img className='object-fit h-40 w-86' src={next} alt='next js icon' />
            {/* <p className='my-4'>Next JS</p> */}
          </div>
          <div className='shadow-md shadow-[#040c16]'>
            <img className='object-fit h-40 w-96' src={tailwind} alt='tailwind icon' />
            {/* <p className='my-4'>Tailwind</p> */}
          </div>
          <div className='shadow-md shadow-[#040c16]'>
            <img className='object-fit h-40 w-86' src={sql} alt='mysql icon' />
            {/* <p className='my-4'>MySQL</p> */}
          </div>
          <div className='shadow-md shadow-[#040c16]'>
            <img className='object-fit h-40 w-86' src={python} alt='python icon' />
            {/* <p className='my-4'>NFT</p> */}
          </div>
          <div className='shadow-md shadow-[#040c16]'>
            <img className='object-fit h-40 w-86' src={vertex} alt='mongo db icon' />
            {/* <p className='my-4'>Mongo DB</p> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Skills
