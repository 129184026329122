import React from 'react'
import big from '../assets/big.png'
import Notesapp from '../assets/notesapp.png'
import Mitsubishi from '../assets/mitsubishi.png'
import multiapp from '../assets/multiapp.png'
import phlask from '../assets/phlask.png'
import cgphilly from '../assets/cgphilly.png'

const Work = () => {
  return (
    <div id='work' className='w-full md:h-screen text-gray-300 bg-[#0a192f]'>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div className='pb-8'>
          <p className='text-4xl font-bold inline border-b-4 text-gray-300 border-[rgb(249,115,115)]'>
            Work
          </p>
          <p className='py-6'>Some of my recent work</p>
        </div>
        <div className='max-w-full grid sm:grid-cols-2 md:grid-cols-3 gap-4'>
          <a href="https://beta.phlask.me/">
              <div className="shadow-md shadow-[#040c16]">
                <img className="object-fit h-auto max-w-full rounded-lg" src={phlask} alt="next js icon" />
                <p className=''>PHLASK</p>
              </div>
          </a>
          <a href="https://josephani-multiapp-hello-uvzsim.streamlit.app/">
              <div className="shadow-md shadow-[#040c16]">
                <img className="object-fit h-auto max-w-full rounded-lg" src={multiapp} alt="next js icon" />
                <p className=''>Multi App Dataframe</p>
              </div>
          </a>
          <a href="https://www.cleanandgreenphilly.org/">
              <div className="shadow-md shadow-[#040c16]">
                <img className="object-fit h-auto max-w-full rounded-lg" src={cgphilly} alt="next js icon" />
                <p className=''>Clean & Green Philly</p>
              </div>
          </a>
          <a href="https://business-idea-generator-6qf2bdrmeqeinsnaysvzgn.streamlit.app/">
              <div className="shadow-md shadow-[#040c16]">
                <img className="object-fit h-auto max-w-full rounded-lg " src={big} alt="next js icon" />
                <p className=''>Business Idea Generator</p>
              </div>
          </a>
          <a href="https://amplify-react-graphql-ixmb.vercel.app/">
              <div className="shadow-md shadow-[#040c16]">
                <img className="object-fit h-auto max-w-full rounded-lg" src={Notesapp} alt="next js icon" />
                <p className=''>Notes App</p>
              </div>
          </a>
          <a href="https://www.mitsubishicars.com/#filter-modal">
              <div className="shadow-md shadow-[#040c16]">
                <img className="object-fit h-auto max-w-full rounded-lg" src={Mitsubishi} alt="next js icon" />
                <p className=''>Mitsubishi Mirage</p>
              </div>
          </a>
        </div>
      </div>
    </div>
  )
}

export default Work
